import React from 'react';



const PrivacyPolicy = () => {
  return (
    <div>
      <h1>VocPrep Privacy Policy</h1>
      <p>
  
This Privacy Policy explains how VocPrep ("we," "us," or "our") collects, uses, and protects your personal information when you use our website (www.VocPrep.com) and related services (collectively, the "Service").

Information We Collect

When you use our Service, we may collect the following types of personal information:

Information You Provide: We collect information that you voluntarily provide when using our website, such as when you create an account, make a reservation, or contact customer support. This may include your name, email address, phone number, and payment details.
Automatically Collected Information: We automatically collect certain information about your device and how you interact with our website. This may include your IP address, browser type, operating system, and usage data (e.g., pages visited, referring website, timestamps).
How We Use Your Information

We use the collected information for the following purposes:

To provide and maintain our Service, including processing bookings and reservations.
To personalize your experience and improve our website.
To communicate with you regarding your bookings, inquiries, and customer service requests.
To detect, prevent, and address technical issues

      </p>
    </div>
  );
}

export default PrivacyPolicy;