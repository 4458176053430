import React from 'react';
import { Link } from "react-router-dom";
import '../../App.css';

import HeroSection from '../HeroSection';
import Footer from '../Footer';

const responsive = {
  desktop: {
    breakpoint: { max:3000, min:1024 },
    items : 3,
    slidesToSlide : 1 // optional, default to 1.
  }
  
};
function Home() {

  console.log( {breakpoint: { max:3000, min:1024 },items : 3
   // slidesToSlide : 3 // optional, default to 1.
  });   
  return (
    <>

      <HeroSection />
   
    </>
  );
}

export default Home;
