import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Contact.css';


const Contact = () => {
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const navigate = useNavigate();
  const endpoint = 'https://7vldzerrpe.execute-api.us-east-1.amazonaws.com/default/sendContactEmail' 
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const data = { email,message,name,phone}
    const fetchPromise = fetch(endpoint, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      body: JSON.stringify(data)
    });
    fetchPromise
      .then(response => response.json())
      .then(data => {
        console.log(data); // handle response, catch errors
    });
    navigate('/ContactSuccess')
  }
  return (
    <div id="containter">
      <h1 id="title">Get in Touch</h1>
      <p id="intro">Interested in learning about partnership opportunities></p>
      <form class="form" action={endpoint} onSubmit={handleSubmit} method="POST">
        <input id="name" type="text" placeholder="name" value={name} onChange={(e) => setName(e.target.value)} />
        <input id="phone" type="text" placeholder="phone number" value={phone} onChange={(e) => setPhone(e.target.value)} />
        <input id="email" type="email" placeholder="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        <textarea id="message" placeholder="message" value={message} onChange={(e) => setMessage(e.target.value)} />
        <button id="submit" disabled={!email} type="submit">Send</button>
      </form>
      <div id="extra"></div>
    </div>

  )
}
export default Contact;